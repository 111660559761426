import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Box = styled.footer`
  padding: 80px 60px;
  background: ${props => props.theme.colors.primary};
  bottom: 0;
  width: 100%;
  bottom: 0px;
  left: 0px;
  right: 0px;
  
  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    /* background: red; */
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
   
  @media (max-width: 1000px) {
    grid-template-columns:0fr 1fr;
  }
`;

const Heading = styled.p`
  font-size: 24px;
  color: #fff;
  margin-bottom: 40px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Footer = () => (
  <Box>
    <Container>
      <Row>
        <Column>
        </Column>
        <Column>
          <Link to="/contact/">
            <Heading>Contact Us</Heading>
          </Link>
        </Column>
      </Row>
    </Container>
  </Box>
)

export default Footer
